import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { LandingComponent } from './landing/landing.component';
import { OverviewComponent } from './overview/overview.component';
import { SecurityComponent } from './security/security.component';
import { MetricsComponent } from './metrics/metrics.component';
import { TeaserComponent } from './teaser/teaser.component';


const COMPONENTS_LIST = [
    LandingComponent,
    OverviewComponent,
    SecurityComponent,
    MetricsComponent,
    TeaserComponent
];

@NgModule ({
    declarations: [COMPONENTS_LIST],
    imports: [CommonModule, IonicModule],
    exports: [COMPONENTS_LIST],
    entryComponents: []
})

export class ComponentsModule { }
