import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnChanges {

  @Input() section: string;

  constructor() { }

  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges): void {
    /* for (let propName in changes) {
      let change = changes[propName];
      let curVal = JSON.stringify(change.currentValue);
      let prevVal = JSON.stringify(change.previousValue);

      console.log('curVal', curVal);
      console.log('prevVal', prevVal);
    } */
  }

}
