import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, Renderer2, ElementRef, OnDestroy } from '@angular/core';
import { DATAVARS } from 'src/app/home/home.page';
// import { trigger, style, animate, transition, state } from '@angular/animations';
import { Animation, AnimationController } from '@ionic/angular'
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.scss'],
})
export class TeaserComponent implements OnInit, OnChanges, OnDestroy {

  @Input() section: string;
  @Input() sizing: DATAVARS;
  @Output() done: any = new EventEmitter();

  activeElement: number = 0;

  isDarkTheme: boolean = false;

  activeImage: string = "../../assets/login.png";

  imageList = {
    0 : "../../assets/login.png",
    1 : "../../assets/dashboard.png", 
    2 : "../../assets/user.png",
    3 : "../../assets/bulletin.png",
    4 : "../../assets/resources.png"
  };

  activeImageBS: BehaviorSubject<string> = new BehaviorSubject("../../assets/login.png");
  activeImage$!: Observable<string>;

  ph0: any;
  ph1: any;
  ph2: any;
  ph3: any;
  ph4: any;
  ph: any[] = [];
  smartphoneContent: any;

  phImage: any;

  private imgAnimate?: Animation;
  private san1?: Animation;
  private san2?: Animation;
  private san3?: Animation;
  private san4?: Animation;
  private han1?: Animation;
  private han2?: Animation;
  private han3?: Animation;
  private han4?: Animation;


  constructor(public renderer: Renderer2, public element: ElementRef, private animationCntl: AnimationController) { 
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      this.activeImage = "../../assets/dark-login.png";
      

      this.imageList = {
        0 : "../../assets/dark-login.png",
        1 : "../../assets/dark-dashboard.png", 
        2 : "../../assets/dark-user.png",
        3 : "../../assets/dark-bulletin.png",
        4 : "../../assets/dark-resources.png"
      };
    }
  }

  ngOnInit() {
    this.activeImageBS.next(this.activeImage);
    this.activeImage$ = this.activeImageBS.asObservable();
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e => {
        const colorScheme = e.matches ? "dark" : "light";
        this.changeTheme((e.matches ? "dark" : "light") === 'dark' ? true : false);
      })); 
    this.changeTheme(window.matchMedia("(prefers-color-scheme: dark)").matches);

    let content = this.element.nativeElement;
    this.smartphoneContent = document.getElementById('smartphoneContent');
    this.ph0 = document.getElementById('ph0');
    this.ph1 = document.getElementById('ph1');
    this.ph2 = document.getElementById('ph2');
    this.ph3 = document.getElementById('ph3');
    this.ph4 = document.getElementById('ph4');
    for (let i = 0; i <= 4; i++) {
      this.ph[i] = document.getElementById('ph' + i);
    }
    // this.phImage = document.getElementById('phImage');
    // console.log(this.ph);
    this.imgAnimate = this.animationCntl.create()
      .addElement(document.querySelector('phImage'))
      .duration(500)
      .onFinish(() => console.log('done'))
  }
  ngOnDestroy(): void {
    this.activeImageBS.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    /* for (let propName in changes) {
      let change = changes[propName];
      let curVal = JSON.stringify(change.currentValue);
      let prevVal = JSON.stringify(change.previousValue);

      console.log('curVal', curVal);
      console.log('prevVal', prevVal);
    } */
  }
  changeTheme(isDark: boolean) {
    this.isDarkTheme = isDark;
    if (this.isDarkTheme) {
      this.activeImage = "../../assets/dark-login.png";
      this.imageList = {
        0 : "../../assets/dark-login.png",
        1 : "../../assets/dark-dashboard.png", 
        2 : "../../assets/dark-user.png",
        3 : "../../assets/dark-bulletin.png",
        4 : "../../assets/dark-resources.png"
      };
    } else {
      this.activeImage = "../../assets/login.png";
      this.imageList = {
        0 : "../../assets/login.png",
        1 : "../../assets/dashboard.png", 
        2 : "../../assets/user.png",
        3 : "../../assets/bulletin.png",
        4 : "../../assets/resources.png"
      };
    }
    this.activeImageBS.next(this.activeImage);
  }
  isHovering(ev: number) {
    if (this.activeElement !== ev) {
      this.renderer.removeClass(document.getElementById(`ph${this.activeElement}`), 'showImg')
      this.renderer.addClass(document.getElementById(`ph${this.activeElement}`), 'hideImg')

      this.renderer.removeClass(document.getElementById(`ph${ev}`), 'hideImg')
      this.renderer.addClass(document.getElementById(`ph${ev}`), 'showImg')
      
      this.activeElement = ev;
      this.activeImage = this.imageList[ev];
      this.activeImageBS.next(this.activeImage);
    } 
  }
  exitHovering(ev: number) {
    if (this.activeElement !== 0) {
      this.activeElement = 0;
      this.activeImage = this.imageList[0];
      this.activeImageBS.next(this.activeImage);
      this.renderer.removeClass(document.getElementById(`ph0`), 'hideImg')
      this.renderer.addClass(document.getElementById(`ph0`), 'showImg')
      this.renderer.addClass(document.getElementById(`ph${ev}`), 'hideImg')
      this.renderer.removeClass(document.getElementById(`ph${ev}`), 'showImg')
    }
  }

}
