import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {

  @Input() fire: boolean;
  @Input() title: string;
  @Output() done: any = new EventEmitter();

  constructor() { }

  ngOnInit() {}

}
